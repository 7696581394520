//=================================================
// Icons
//=================================================

.fas {
	&.fas-space-l {
		padding-left: 0.737rem;
	}

	&.fas-space-r {
		padding-right: 0.737rem;
	}
}

.search-form {
	.search {
		.fas {
			&.fa-search {
				font-size: 1rem;
			}
		}
	}
}

/*=============================
// Star raiting
=============================*/
.star-raiting {
	@extend .d-flex;
	@extend .no-space;
	color: #f8bc1c;

	[class*='fa-'] {
		margin-left: 0.3125rem;
		font-size: 0.8125rem;
		@extend .align-self-center;

		&:first-child {
			margin-left: 0;
		}
	}
}

/*=============================
// Close
=============================*/
.close {
	opacity: 1;
	@extend .transition-all;

	> span {
		&:not(.adveits-i) {
			@include lhCrop-b(1, 0.55);
			@include lhCrop-a(1, 0.55);
		}
	}

	&:hover {
		color: $body_links_hover_color;
		@extend .transition-all;

		.adveits-i {
			&.adv-close {
				span {
					background-color: $body_links_hover_color;
				}
			}
		}
	}

	&:not(:disabled):not(.disabled) {
		&:hover, &:focus {
			opacity: 1;
		}
	}
}
