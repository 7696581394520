//=================================================
// services
//=================================================

.services-slider-control {
    padding-top: $space_sm;
    padding-top: $space_sm;
}

.adv-slider-services {
    border-top: 1px solid $body_border_color;
    padding-top: $space_sm;

    .adv-swiper-container {
        padding-bottom: 5.875rem;

        .adv-2-swiper-button {
            &-prev, &-next {
                top: auto;
                bottom: 0;
                transform: translateY(0);

                &.adv-swiper-button-disabled {
                    display: none;
                }
            }
        }
    }
}

.adv-services-pagination {
    position: static;
    padding-top: 0.625rem;
    width: auto;
}

/*=============================
// Card services
=============================*/
.card-services {
    padding-top: $space_sm;

    @extend .style-default;
    border: 0;
    height: 100%;

    .card-title {
        &:first-child {
            &:before {
                display: none;
            }
        }
    }

    .card-body {
        padding: 0;

        .card-text {
            min-height: 6rem;
        }
    }
}
