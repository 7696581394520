//=================================================
// Clients
//=================================================

.clients {
	.clients-items {
		margin-bottom: -7%;

		@media #{$media_md} {
			margin-bottom: -15%;
		}

		.clients-item {
			width: 25%;
			float: left;
			position: relative;
			text-align: center;
			padding-bottom: 7%;

			@media #{$media_md} {
				width: 50%;
				padding-bottom: 15%;
			}

			.clients-item-body {
				max-width: 11.632rem;
				text-align: center;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);

				img {
					max-width: 100%;
					max-height: 4.6875rem;
				}
			}
		}
	}
}
