//=================================================
// About me
//=================================================

.about-me-img {
	position: relative;

	.img {
		&.object-fit {
			min-height: 25rem;
			max-height: 51.25rem;

			@media #{$media_md} {
				max-height: 25rem;
			}

			&:before {
				padding-top: 121%;
			}
		}
	}

	.img-2 {
		position: absolute;
		width: 45%;
		bottom: -7%;
		right: -1.563rem;

		img {
			width: 100%;
		}
	}
}
