//=================================================
// Variables
//=================================================

//=============================
// Media queries
//=============================
$retina:     "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";
$media_xxxl: "(min-width: 1920px)";
$media_xxl:  "(max-width: 1700px)";
$media_xl:   "(max-width: 1199px)";
$media_lg:   "(max-width: 991px)";
$media_md:   "(max-width: 767px)";
$media_sm:   "(max-width: 575px)";

//=============================
// Paths
//=============================
$img:          "../img/";
$fonts:        "../fonts/";
$icons:        "../icons/";

$fa-font-path: "../../fonts/FontAwesome";

//=============================
// Colors
//=============================
$white:                                               #ffffff;
$blue:                                                #4b70b8;
$indigo:                                              #6b34c6;
$purple:                                              #533588;
$pink:                                                #da72a2;
$red:                                                 #b11f2d;
$orange:                                              #df9659;
$yellow:                                              #f8bc1c;
$green:                                               #2a9264;
$teal:                                                #339578;
$cyan:                                                #257887;

$gray:                                                #e9e9e9;
$gray-light:                                          #f7f7f7;
$gray-dark:                                           #979797;

$light:                                               #e9e9e9;
$dark:                                                #979797;

// Body
$body_text_primary_color:                             #767676; 
$body_text_secondary_color:                           #323232; 
$body_text_tertiary_color:                            #ffffff; // For dark background
$body_headline_color:                                 #323232; 
$body_links_color:                                    #323232; 
$body_links_hover_color:                              #767676;
$body_background_color:                               #ffffff; 

$body_main_element_primary_color:                     #323232; 
$body_main_element_secondary_color:                   #f7f7f7; 
$body_border_color:                                   #e9e9e9; 
$body_mark_background:                                #323232;  

// Header
$header_background_color:                             #ffffff;

// Footer
$footer_background_color:                             #f7f7f7;

// Page title
$page_title_background_color:                         #f7f7f7;

// Menu
$menu_links_color:                                    #767676;
$menu_links_hover_color:                              #323232;
$menu_links_active_color:                             #323232;

// Dropdown
$dropdown_links_color:                                #767676; 
$dropdown_links_hover_color:                          #323232;
$dropdown_links_active_color:                         #323232; 
$dropdown_links_background_color:                     #ffffff; 
$dropdown_links_hover_background_color:               #ffffff; 
$dropdown_links_active_background_color:              #ffffff; 

// Forms
$input_text_color:                                    #797979; 
$input_border_focus_color:                            #767676; 
$input_placeholder_color:                             #797979; 
$label_color:                                         #323232; 

// Form primary
$input_background_primary_color:                      transparent;
$input_border_primary_color:                          #e9e9e9;

// Form secondary
$input_background_secondary_color:                    #f7f7f7;
$input_border_secondary_color:                        transparent; 

// Modals
$modal_background_color:                              #f7f7f7;
$modal_background_content_color:                      transparent; 

// Card
$card_border_color:                                   transparent; 
$card_background_color:                               transparent; 

// Widget
$widget_background_color:                             #f7f7f7;
$widget_border_color:                                 transparent; 
$widget_headline_color:                               #323232;

// Comment
$comment_background_color:                            #f7f7f7;

// Slider
$slider_button_color:                                 #ffffff; 
$slider_button_background_color:                      rgba(50, 50, 50, 0.2); 
$slider_button_hover_color:                           #ffffff; 
$slider_button_hover_background_color:                rgba(50, 50, 50, 1); 

// Pop-Up
$popup_text_color:                                    #ffffff;
$popup_background_color:                              rgba(0, 0, 0, 0.8);
$popup_button_color:                                  #ffff;
$popup_button_hover_color:                            #767676;
$popup_button_background_color:                       transparent;
$popup_button_background_hover_color:                 transparent;

//=============================
// Fonts
//=============================
$WORKSANS:                                           'WorkSans-Regular', sans-serif; 
$WORKSANS_b:                                         'WorkSans-Bold', sans-serif; 
$WORKSANS_l:                                         'WorkSans-Light', sans-serif; 

$font_primary:                                        $WORKSANS; 
$font_primary_bold:                                   $WORKSANS_b; 
$font_primary_light:                                  $WORKSANS_l; 

$f_size_sm:                                           0.75rem;  // 12px
$f_size_df:                                           0.875rem; // 14px
$f_size_lg:                                           1rem;     // 16px

//=============================
// Spacing
//=============================
$space_xl:                                            8.75rem;  // 140px 
$space_lg:                                            3.75rem;  // 60px 
$space_md:                                            3.125rem; // 50px  
$space_sm:                                            1.875rem; // 30px 
$space_xs:                                            1.25rem;  // 20px 

// For page content
$space_pc_primary:                                    1.875rem; // 30px
$space_pc_secondary:                                  1.875rem; // 30px

//=============================
// Header
//=============================
$header_padding_t:                                    1.875rem; 
$header_padding_b:                                    1.875rem; 

//=============================
// Main
//=============================
$main_padding_t:                                      0;
$main_padding_b:                                      8.75rem;

//=============================
// Footer
//=============================
$footer_padding_t:                                    3.125rem; 
$footer_padding_b:                                    3.125rem; 

//=============================
// Wrapper
//=============================
$wrapper_max_w:                                       1800px;

// Left
$wrapper_p_l:                                         3.125rem;
$wrapper_p_l_xxl:                                     3.125rem;  
$wrapper_p_l_xl:                                      1.875rem; 
$wrapper_p_l_sm:                                      1.875rem; 

// Right
$wrapper_p_r:                                         3.125rem;
$wrapper_p_r_xxl:                                     3.125rem; 
$wrapper_p_r_xl:                                      1.875rem; 
$wrapper_p_r_sm:                                      1.875rem; 

//=============================
// Transition s
//=============================
$transition-s:                                        0.3s;

//=============================
// Components
//=============================
$border_radius:                                       0.25rem;
$border_radius_sm:                                    0.3rem;
$border_radius_lg:                                    0.2rem;

$caret_vertical_align:                                middle;
$caret_spacing:                                       0.263rem;

$disabled_opacity:                                    0.5;

//=============================
// Typography
//=============================
$font_family_base:                                    $font_primary;

$h1_font_size:                                        3.063rem; // 49px 
$h2_font_size:                                        2.438rem; // 39px 
$h3_font_size:                                        1.938rem; // 31px 
$h4_font_size:                                        1.563rem; // 25px 
$h5_font_size:                                        1.25rem;  // 20px 
$h6_font_size:                                        1rem;     // 16px 

$line_height_base:                                    1;
$body_color:                                          $body_text_primary_color;

$hr_border_color:                                     $body_border_color;

// Font margin bottom
$h1_font_mb:                                          0.833rem; 
$h2_font_mb:                                          0.514rem; 
$h3_font_mb:                                          0.436rem; 
$h4_font_mb:                                          0.308rem; 
$h5_font_mb:                                          0.246rem; 
$h6_font_mb:                                          0.197rem;
$p_font_mb:                                           0.394rem; 
$li_font_mb:                                          0.394rem; 

//=============================
// Links
//=============================
$link_color:                                          $body_links_color;
$link_hover_color:                                    $body_links_hover_color;
$link_hover_decoration:                               none;

//=============================
// Buttons
//=============================
$btn_font_family:                                     $font_primary_bold;
$btn_font_weight:                                     700;

$btn_padding_y:                                       1.313rem;
$btn_padding_x:                                       1.313rem;
$btn_padding_y_sm:                                    0.563rem;
$btn_padding_x_sm:                                    0.563rem;
$btn_padding_y_lg:                                    1.938rem;
$btn_padding_x_lg:                                    1.938rem;

$btn_font_size:                                       $f_size_df;
$btn_font_size_sm:                                    $f_size_sm;
$btn_font_size_lg:                                    $f_size_lg;

$btn_line_height:                                     1.23;
$btn_line_height_sm:                                  1.23;
$btn_line_height_lg:                                  1.23;

$btn_border_radius:                                   0;
$btn_border_radius_sm:                                0;
$btn_border_radius_lg:                                0;

$btn_focus_width:                                     0;

$btn_transition:                                      color $transition-s ease-in-out, background-color $transition-s ease-in-out, border-color $transition-s ease-in-out, box-shadow $transition-s ease-in-out;

//=============================
// Badge
//=============================
$badge_padding_y:                                     0.563rem; 
$badge_padding_x:                                     0.563rem;  
$badge_border_radius:                                 0;

//=============================
// Modals
//=============================
$modal_inner_padding:                                 1.526rem 0;
$modal_dialog_margin:                                 1.526rem;

$modal_content_bg:                    	              $modal_background_content_color;
$modal_content_border_color:                          transparent;
$modal_content_border_width:                          0;
$modal_content_border_radius:                         0;

$modal_backdrop_bg:                  	              $modal_background_color;
$modal_backdrop_opacity:              	              1;
$modal_header_border_color:                           transparent;
$modal_header_border_width:                           0;
$modal_footer_border_color:                           transparent;
$modal_footer_border_width:                           0;

$modal_header_padding_y:                              $header_padding_t;
$modal_header_padding_x:                              0;
$modal_header_padding:                                $header_padding_t 0 $header_padding_b 0;

//=============================
// Alert
//=============================
$alert_padding_y:                                     0.90rem;
$alert_padding_x:                                     1.10rem;
$alert_margin_bottom:                                 $space_sm;
$alert_border_radius:                                 0;

//=============================
// List group
//=============================
$list_group_bg:                                       $body_background_color;
$list_group_border_color:                             $body_border_color;
$list_group_border_radius:                            0;

$list_group_item_padding-y:                           $space_xs;
$list_group_item_padding-x:                           $space_sm;

//=============================
// Figures
//=============================
$figure_caption_font_size:                            $f_size_sm;
$figure_caption_color:                                $body_text_primary_color;

//=============================
// Close
//=============================
$close_color:                                         $body_links_color;

//=============================
// Forms
//=============================
$label_margin_bottom:                                 0;

$input_padding_y:                                     1.158rem / 1.5;
$input_padding_x:                                     1.579rem / 1.5;
$input_padding_y_sm:                                  1.158rem / 2.5;
$input_padding_x_sm:                                  1.579rem / 2.5;
$input_padding_y_lg:                                  1.158rem;
$input_padding_x_lg:                                  1.579rem;
$input_font_size:                                     $f_size_df;
$input_font_size_sm:                                  $f_size_sm;
$input_font_size_lg:                                  $f_size_lg;
$input_line_height:                                   1.31;
$input_line_height_sm:                                1.31;
$input_line_height_lg:                                1.31;

$input_bg:                                            $input_background_secondary_color;

$input_color:                                         $input_text_color;

$input_border_color:                                  $input_border_secondary_color;
$input_border_radius:                                 0;
$input_border_radius_lg:                              0;
$input_border_radius_sm:                              0;

$input_focus_border_color:                            $input_border_focus_color;
$input_focus_box_shadow:                              inherit;

$input_height:                                        auto;
$input_height_sm:                                     auto;
$input_height_lg:                                     auto;

$form_check_input_gutter:                             1.526rem;
$form_check_input_margin_y:                           0;
$form_check_input_margin_x:                           0.526rem;

$form_group_margin_bottom:                            $space_sm;

$input_group_addon_bg:                                $body_border_color;

$custom_control_gutter:                               0.8rem;

$custom_control_indicator_size:                       1rem;
$custom_control_indicator_checked_bg:                 $body_main_element_primary_color;
$custom_control_indicator_border_color:               $body_text_primary_color;

$custom-control-indicator-active-bg:                  $body_main_element_primary_color;
$custom-control-indicator-active-border-color:        $body_main_element_primary_color;

$custom_checkbox_indicator_border_radius:             0;

$custom_select_border_radius:                         0;
$custom_select_focus_box_shadow:                      inherit;

//=============================
// Buttons + Forms
//=============================
$input_btn_focus_box_shadow:                          inherit;

//=============================
// Navs
//=============================
$nav_link_disabled_color:                             rgba($menu_links_color, $disabled_opacity);

$nav_tabs_border_radius:                              0;
$nav_tabs_link_active_color:                          $menu_links_active_color;

$nav_pills_border_radius:                             0;

//=============================
// Dropdowns
//=============================
$dropdown_min_width:                                  13.6875rem;
$dropdown_padding_y:                                  1.25rem;
$dropdown_spacer:                                     0;
$dropdown_font_size:                                  0.789rem;
$dropdown_color:                                      $dropdown_links_color;
$dropdown_border_color:                               $body_border_color; 
$dropdown_bg:                                         $dropdown_links_background_color;
$dropdown_border_radius:                              0;
$dropdown_border_width:                               1px;
$dropdown_divider_bg:                                 $body_border_color;
$dropdown_divider_margin_y:                           0;

$dropdown_link_color:                                 $dropdown_links_color;
$dropdown_link_hover_color:                           $dropdown_links_hover_color;
$dropdown_link_hover_bg:                              $dropdown_links_hover_background_color;

$dropdown_link_active_color:                          $dropdown_links_active_color;
$dropdown_link_active_bg:                             $dropdown_links_active_background_color;
$dropdown_link_disabled_color:                        rgba($dropdown_links_color, $disabled_opacity);

$dropdown_item_padding_y:                             0.6875rem;
$dropdown_item_padding_x:                             2.1875rem;

//=============================
// Pagination
//=============================
$pagination_padding_x:                                0.953rem;
$pagination_padding_y:                                0.632rem;

$pagination_bg:                                       $body_background_color;
$pagination_border_width:                             0;
$pagination_border_color:                             transparent;

$pagination_hover_border_color:                       transparent;

$pagination_disabled_color:                           rgba($body_links_color, $disabled_opacity);
$pagination_disabled_bg:                              transparent;
$pagination_disabled_border_color:                    transparent;

//=============================
// Card
//=============================
$card_spacer_y:                                       $space_sm;
$card_spacer_x:                                       $space_sm;
$card_cap_bg:                                         $card_background_color;
$card_bg:                                             $card_background_color;
$card_border_color:                                   $card_border_color;
$card_border_radius:                                  0;
$card_inner_border_radius:                            0;
$card_img_overlay_padding:                            $space_sm;

//=============================
// Table
//=============================
$table_caption_color:                                 $body_text_primary_color;
