//=================================================
// Portfolio
//=================================================

.portfolio-items {
	.portfolio-item {
		@extend .style-default;
		display: block;
		position: relative;
		overflow: hidden;

		.portfolio-item-content{
			position: absolute;
			left: 0;
			top: 50%;
			@include transform(translateY(-50%));
			@extend .transition-transform;
			width: 100%;
			padding: $space_sm;
			z-index: 3;
			max-width: 31.25rem;

			.portfolio-item-category {
				color: $body_text_tertiary_color !important;
				opacity: 0.688;
			}

			// Colors
			color: $body_text_tertiary_color;
		}

		.img {
			.object-fit-cover {
				@extend .transition-all;
				transform: perspective(1px) scale(1) translateZ(0);
				backface-visibility: hidden;
			}

			&.object-fit {
				min-height: 25rem;
				max-height: 31.25rem;

				@media #{$media_md} {
					max-height: 25rem;
				}

				&:before {
					padding-top: 92.6%;
				}
			}
		}

		.img-bg-color {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			background: $body_main_element_primary_color;
			opacity: 0.2;
			transition: opacity $transition-s ease-in-out;
		}

		&:hover {
			.img {
				.object-fit-cover {
					transform: perspective(1px) scale(1.1) translateZ(0);
				}
			}

			.img-bg-color {
				opacity: 0.5;
			}
		}
	}
}

/*=============================
// Portfolio items slider
=============================*/
.adv-slider-portfolio {
	.portfolio-items {
		.portfolio-item {
			float: left;
			width: 33.33%;

			@media #{$media_md} {
				width: 100%;
			}
		}
	}

	.adv-swiper-container {
		.adv-swiper-wrapper {
			width: 95%;

			@media #{$media_sm} {
				width: 90%;
			}
		}

		.adv-2-swiper-button {
			&-prev, &-next {
				top: inherit;
				@include transform(translateY(0));
				bottom: $space_sm;

				&.adv-swiper-button-disabled {
					.btn {
						cursor: not-allowed;
					}
				}
			}

			&-prev {
				left: $wrapper_p_l;

				@media #{$media_xxl} {
					left: $wrapper_p_l_xxl;
				}

				@media #{$media_xl} {
					left: $wrapper_p_l_xl;
				}

				@media #{$media_sm} {
					left: $wrapper_p_l_sm;
				}

				.btn {
					padding-left: 1.579rem !important;
					left: 0;
				}
			}

			&-next {
				right: $wrapper_p_r;

				@media #{$media_xxl} {
					right: $wrapper_p_r_xxl;
				}

				@media #{$media_xl} {
					right: $wrapper_p_r_xl;
				}

				@media #{$media_sm} {
					right: $wrapper_p_r_sm;
				}

				.btn {
					padding-right: 1.579rem !important;
					right: 0;
				}
			}
		}
	}
}

/*=============================
// Gallery grid gutter
=============================*/
.row {
	&.with-pb-md {
		&.gallery-items {
			margin-bottom: -$space_md;

			@media #{$media_xl} {
				margin-bottom: -$space_md / 1.65;
			}

			@media #{$media_sm} {
				margin-bottom: 0;
			}

			> .col,
			> [class*='col-'] {
				padding-bottom: $space_md;

				@media #{$media_xl} {
					padding-bottom: $space_md / 1.65;
				}

				@media #{$media_sm} {
					&:last-child {
						padding-bottom: 0;
					}
				}
			}
		}
	}
}

/*=============================
// Gallery item
=============================*/
.gallery-item {
	@extend .style-default;
	display: block;
	position: relative;
	overflow: hidden;

	.img {
		.object-fit-cover {
			@extend .transition-all;
			transform: perspective(1px) scale(1) translateZ(0);
			backface-visibility: hidden;
		}

		&.object-fit {
			min-height: 25rem;
			max-height: 40.3125rem;

			@media #{$media_md} {
				max-height: 25rem;
			}

			&:before {
				padding-top: 80%;
			}
		}
	}

	.img-bg-color {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		background: $body_main_element_primary_color;
		opacity: 0.2;
		transition: opacity $transition-s ease-in-out;
	}

	&:hover {
		.img {
			.object-fit-cover {
				transform: perspective(1px) scale(1.1) translateZ(0);
			}
		}

		.img-bg-color {
			opacity: 0.5;
		}
	}
}
