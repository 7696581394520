//=================================================
// Collapse
//=================================================

.accordion {
	.btn-link {
		width: 100%;
		text-align: left;
		padding: 0;
		border: 0;
	}
}
